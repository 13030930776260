import React from 'react'
import { Image } from '../BlockLibrary/Common/Image';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';

const HeaderOnlyALogo = ({ header, isPreview }) => {
     if (isPreview) {
          header = header.fields;
     }

     return (
          <div className="container logo-only">
               <div className="logo-wrap">
                    <a href={process.env.PI_DOMAIN} >
                         <Image image={header.logo} />
                    </a>
               </div>
          </div>
     )
}

HeaderOnlyALogo.propTypes = {
     isPreview: PropTypes.bool
}

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     }
}

const DefaultHeaderOnlyALogo = connect(mapStateToProps)(HeaderOnlyALogo)

export default DefaultHeaderOnlyALogo;