import React from 'react';
import { ContentfulNavigationMenu } from '../BlockLibrary';
import { Image } from '../BlockLibrary/Common/Image';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";
import Block from '../block';

const HeaderHz = ({ header, isPreview, specificPhone }) => {
     let phoneNumber = specificPhone != null ? specificPhone : header.phone;
     if (isPreview == true) {
          header = header.fields;
     }
     return (
          <div className="container">
               <div className="is-desktop">
                    <div className="top-header healthzone">
                         <div className="logo">
                              <a href={process.env.PI_DOMAIN}>
                                   <Image image={header.logo} />
                              </a>
                         </div>
                         <div className="logo-healthzone">
                              <a href="/healthzone/">
                                   <Image image={header.logoHz} />
                              </a>
                         </div>

                         <p>{header.textInHeaderHz}</p>

                         <Block block={header.ctaButton} />
                    </div>
                    <nav className="bottom-header healthzone">
                         <ul className="main-menu" id="bottom-main-menu-desktop">
                              {header.mainMenu && < ContentfulNavigationMenu menuItem={header.mainMenu} />}
                         </ul>
                         <ul className="list-inline">
                              {header.topMenu && <ContentfulNavigationMenu menuItem={header.topMenu} classItem={`list-inline-item`} />}
                         </ul>
                    </nav>
               </div>
               <div className="is-mobile header-mobile">
                    <div className="top-header healthzone">
                         <button className="menu-toggle-button" aria-label="Menu toggle">
                              <span className="icon-bar bar1"></span>
                              <span className="icon-bar bar2"></span>
                              <span className="icon-bar bar3"></span>
                         </button>
                         <div className="logo">
                              <a href={process.env.PI_DOMAIN} >
                                   <Image image={header.logo} />
                              </a>
                         </div>
                         <div className="logo-healthzone">
                              <a href="/healthzone/" >
                                   <Image image={header.logoHz} />
                              </a>
                         </div>
                    </div>
                    <nav className="bottom-header">
                         <ul className="main-menu-mobile" id="bottom-main-menu-mobile">
                              {header.mainMenu && <ContentfulNavigationMenu menuItem={header.mainMenu} />}
                              {header.topMenu && <ContentfulNavigationMenu menuItem={header.topMenu} />}
                         </ul>
                    </nav>
               </div>
          </div>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

HeaderHz.propTypes = {
     isPreview: PropTypes.bool,
}

const DefaultHeaderHz = connect(mapStateToProps)(HeaderHz);


export default DefaultHeaderHz;
